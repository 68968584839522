import { getRequest, monitorGateway ,postRequest } from "@/utils/request";
import { post } from "jquery";
// 安防联控
export const securityControlApi = {
  // PLC,红外，灯光，风机，水泵，声光报警
  querySecurityControl: (query) => {
    return getRequest(`${monitorGateway}/safe/plc/station/id`, query);
  },
  //
  queryElectricalSafety: (query) => {
    return getRequest(`${monitorGateway}/safe/station/id`, query);
  },
  //
  queryPartialDischarge: (query) => {
    return getRequest(`${monitorGateway}/safe/station/id/jufang`, query);
  },
  //
  partialDischargeChart: (query) => {
    return getRequest(`${monitorGateway}/safe/station/id/jufang/list`, query);
  },
  partialDischargeList: (query) => {
    return getRequest(`${monitorGateway}/safe/page/list`, query);
  },
  //空调按钮点击
  kongtiao: (params)=>{
    return postRequest(`${monitorGateway}/safe/put/kong/tiao`,params)
  } ,
  // 安防联控
  plcList:(params)=>{
    return getRequest(`${monitorGateway}/safe/power/plc/station/id`,params)
  },
  // 用电安全->变压器
  controlList:(params)=>{
    return getRequest(`${monitorGateway}/safe/power/zaosheng/station/id`,params) 
  },
  //消防安全
  fireSafetyList:(params)=>{
    return getRequest(`${monitorGateway}/safe/power/yanwu/station/id`,params)
  }
};
