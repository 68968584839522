<!-- 安防联控 -->
<template>
  <div id="light-device">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>空调控制</span>
      </div>
      <div class="query-form">
        <div class="query-button">
          <el-button style="background-color: #1970ca;" type="primary" icon="el-icon-refrigeration" @click="createDialog('制冷')">制冷</el-button>
          <el-button style="background-color: #ebb849;" type="primary" icon="el-icon-heat" @click="createDialog('制热')">制热</el-button>
          <el-button style="background-color: #db2727;" type="primary" icon="el-icon-shutdown" @click="createDialog('关机')">关机</el-button>
          <el-button style="background-color: #22b6b8;" type="primary" icon="el-icon-arefaction" @click="createDialog('除湿')">除湿</el-button>
        </div>
      </div>
      <ul class="tabs">
          <li v-for="item in typeList" :key="item.type" :class="typeLabel == item.value ? 'select' : ''"
            @click="switchTabs(item)">
            {{ item.name }}
          </li>
        </ul>
      <div class="content">
        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="deviceName">
            <template slot-scope="scope">
              {{ selectName }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备编号" prop="deviceNum">
          </el-table-column>
          <el-table-column align="center" label="动作" prop="cause">
          </el-table-column>
          <el-table-column align="center" label="时间" prop="createTime">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="556px" @close="dialogClose">
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" :model="formData">
          <el-form-item label="密码" prop="passWord">
            <el-input v-model.trim="formData.passWord" maxlength="12" show-password placeholder="请输入密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="kongtiao" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { securityControlApi } from "@/api/securityControl.js";
export default {
  name: "",

  data() {
    return {
      selectName:'门禁',
      tableData: [],
      requestParam: {
        id: "",
        pageSize:10,
        pageNum:1,
      },
      inputValue:1,
      total:0,
      formData:{
        passWord:''
      },
      dialogVisible:false,
      dialogTitle:'',
      typeList:[
        {
          name:'门禁',
          value:1,
        },
        {
          name:'红外',
          value:2,
        },
        {
          name:'灯光',
          value:3,
        },
        {
          name:'声光',
          value:6,
        },
      ],
      typeLabel:1,
    };
  },
  created() {
    this.$store.commit("increment", "安全用电");
    this.$store.commit("selectChild", "安防联控");
    this.$store.commit("selectChildren", "");
    this.requestParam.id=sessionStorage.getItem("stationId")
  },
  mounted() {
    this.querySecurityControl();
  },

  methods: {
    switchTabs(item) {
      this.selectName=item.name
      this.typeLabel = item.value;
      this.querySecurityControl()
    },
    kongtiao(){
      let obj ={
        stationId:this.requestParam.id,
        chushi:null,//除湿
        cooling:null,//制冷
        poweroff:null,//关机
        heating:null,//制热
        password:this.formData.passWord
      }
      if(this.dialogTitle=='制冷'){
        obj.cooling=1
      }else if(this.dialogTitle=='制热'){
        obj.heating=1
      }else if(this.dialogTitle=='关机'){
        obj.poweroff=1
      }else{
        obj.chushi=1
      }
      // let obj={
      //   stationId:'',
      //   password:'',
      //   chushi:'',
      // }
      // obj.stationId=this.formData.passWord
      // obj.password='123123'
      // obj.chushi='1'
      securityControlApi.kongtiao(obj).then(res=>{
        console.log(res);
        if(res.code==200){
          this.dialogVisible = false
          this.$message({
            message: res.data,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
        }else{
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    dialogClose(){
      this.formData.passWord=''
      this.dialogTitle=''
    },
    createDialog(title) {
     this.dialogTitle=title;
     this.dialogVisible=true;
    },
    querySecurityControl() {
      let obj={
        id:this.requestParam.id,
        type:this.typeLabel,
        pageNum:this.requestParam.pageNum,
        pageSize:this.requestParam.pageSize,
      }
      securityControlApi.plcList(obj).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data
          this.total = res.total
        }
      });
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.querySecurityControl();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.querySecurityControl();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.querySecurityControl();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.querySecurityControl();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.querySecurityControl();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.querySecurityControl();
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#light-device {
  width: 100%;
  /* min-width: 1621px; */
  min-height: 908px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
}
.table {
  margin-bottom: 16px;
}
.query-button .el-button{
   display: flex;
   align-items: center;
}
::v-deep .el-icon-shutdown{
  background: url("../../assets/images/cockpit/shutdown.png") center no-repeat;
  width: 15px;
  height: 15px;
}
::v-deep .el-icon-arefaction{
  background: url("../../assets/images/cockpit/arefaction.png") center no-repeat;
  width: 15px;
  height: 15px;
}
::v-deep .el-icon-refrigeration{
  background: url("../../assets/images/cockpit/refrigeration.png") center no-repeat;
  width: 15px;
  height: 15px;
}
::v-deep .el-icon-heat{
  background: url("../../assets/images/cockpit/heat.png") center no-repeat;
  width: 15px;
  height: 15px;
}

/* 滚动条样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  width: 24px;
  height: 40px;
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
/* 弹窗 */
.dialog-content {
  margin: 30px 32px 0 27px;
}

.dialog-form :deep() .el-form-item {
  margin: 0;
  margin-bottom: 16px;
}

/* input */
.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 406px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}
.tabs {
  /* width: 1336px; */
  background-color: rgb(4, 25, 66);
  display: flex;
  margin-bottom: 10px;
  padding: 10px 0;
}

.tabs li {
  width: 122px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.tabs li:hover{
  background-color: #002c6c;
  color: #ffc74a;
}
.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
::v-deep .el-table__body-wrapper {
  max-height: 67vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
